import { mainnet, goerli } from 'wagmi/chains'
import { Chain } from 'wagmi'

export const avalandche: Chain = {
  id: 43114,
  name: 'Avalanche C-Chain',
  network: 'avalanche',
  rpcUrls: {
    
    public: {
      
      http: ['https://rpc.ankr.com/avalanche'],
    },
    default: {
      
      http: ['https://rpc.ankr.com/avalanche'],
    },
  },
  nativeCurrency: { name: 'Avalanche', symbol: 'AVAX', decimals: 18 },
  blockExplorers: {
    default: {
      name: 'snowtrace',
      url: 'https://snowtrace.io/',
    },
  },
}

export const avalandcheFuji: Chain = {
  id: 43113,
  name: 'Avalanche Fuji',
  network: 'avalanche-fuji',

  rpcUrls: {
    
    public: {
      
      http: ['https://rpc.ankr.com/avalanche_fuji'],
    },
    default: {
      
      http: ['https://rpc.ankr.com/avalanche_fuji'],
    },
  },
  nativeCurrency: { name: 'Avalanche', symbol: 'AVAX', decimals: 18 },
  blockExplorers: {
    default: {
      name: 'snowtrace',
      url: 'https://testnet.snowtrace.io/',
    },
  },
  testnet: true,
}

export const fantomOpera: Chain = {
  id: 250,
  name: 'Fantom Opera',
  network: 'fantom',
  nativeCurrency: { name: 'Fantom', symbol: 'FTM', decimals: 18 },
  rpcUrls: {
    
    public: {
      
      http: ['https://rpc.ftm.tools'],
    },
    default: {
      
      http: ['https://rpc.ftm.tools'],
    },
  },
  blockExplorers: {
    default: {
      name: 'FTMScan',
      url: 'https://ftmscan.com',
    },
  },
}

export const fantomTestnet: Chain = {
  id: 4002,
  name: 'Fantom Testnet',
  network: 'fantom-testnet',
  nativeCurrency: { name: 'Fantom', symbol: 'FTM', decimals: 18 },
  rpcUrls: {
    
    public: {
      
      http: ['https://rpc.testnet.fantom.network'],
    },
    default: {
      
      http: ['https://rpc.testnet.fantom.network'],
    },
  },
  blockExplorers: {
    default: {
      name: 'FTMScan',
      url: 'https://testnet.ftmscan.com',
    },
  },
  testnet: true,
}
// add ethw mainnet
export const ethwMainnet: Chain = {
  id: 10001,
  name: 'ETHW Chain',
  network: 'ethw-mainnet',
  nativeCurrency: { name: 'ETHW', symbol: 'ETHW', decimals: 18 },
  rpcUrls: {
    
    public: {
      
      http: ['https://mainnet.ethereumpow.org'],
    },
    default: {
      
      http: ['https://mainnet.ethereumpow.org'],
    },
  },
  blockExplorers: {
    default: {
      name: 'ETHWScan',
      url: 'https://www.oklink.com/en/ethw',
    },
  },
  testnet: true,
}

// add ounus testnet

export const onusTestnet: Chain = {
  id: 1945,
  name: 'ONUS Testnet',
  network: 'onus-testnet',
  nativeCurrency: { name: 'ONUS', symbol: 'ONUS', decimals: 18 },
  rpcUrls: {
    
    public: {
      
      http: ['https://rpc-testnet.onuschain.io'],
    },
    default: {
      
      http: ['https://rpc-testnet.onuschain.io'],
    },
  },
  blockExplorers: {
    default: {
      name: 'ONUSExplorer',
      url: 'https://explorer-testnet.onuschain.io',
    },
  },
  testnet: true,
}

// ẩn chain onus

export const onusMainnet: Chain = {
  id: 1975,
  name: 'ONUS Chain',
  network: 'onus-mainnet',
  nativeCurrency: { name: 'ONUS', symbol: 'ONUS', decimals: 18 },
  rpcUrls: {
    
    public: {
      
      http: ['https://rpc.onuschain.io/'],
    },
    default: {
      
      http: ['https://rpc.onuschain.io/'],
    },
  },
  blockExplorers: {
    default: {
      name: 'ONUSExplorer',
      url: 'https://explorer.onuschain.io/',
    },
  },
  testnet: true,
}

const bscExplorer = { name: 'BscScan', url: 'https://bscscan.com' }

export const bsc: Chain = {
  id: 56,
  name: 'BNB Smart Chain',
  network: 'bsc',
  rpcUrls: {
    
    public: {
      
      http: ['https://bsc-dataseed1.binance.org'],
    },
    default: {
      
      http: ['https://bsc-dataseed1.binance.org'],
    },
  },
  blockExplorers: {
    default: bscExplorer,
    etherscan: bscExplorer,
  },
  nativeCurrency: {
    name: 'Binance Chain Native Token',
    symbol: 'BNB',
    decimals: 18,
  },
}

export const bscTest: Chain = {
  id: 97,
  name: 'BNB Testnet',
  network: 'bsc-testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Binance Chain Native Token',
    symbol: 'tBNB',
  },
  rpcUrls: {
    public: {
      http: ['https://data-seed-prebsc-1-s3.binance.org:8545'],
    },
    default: {
      http: ['https://data-seed-prebsc-1-s3.binance.org:8545'],
    },
  },
  blockExplorers: {
    default: { name: 'BscScan', url: 'https://testnet.bscscan.com' },
  },
  testnet: true,
}

export { mainnet, goerli }
