import { createReducer } from '@reduxjs/toolkit'
import {
    valueSearch,
    fetchDataOnMarketplace,
    fetchStatusLoading,
    fetchTotalSellItem,
    fetchCartItems,
    selectDenominations,
    selectPaymentPeriod,
    selectNextInterestPaymentDate,
    selectCurrentYield,
    fetchDataUserStatus,
    fetchDataUserAction
} from "./actions"
import { CartItem, Items, UserItems } from "./type"

interface global {
    fillPrice: string,
    listItem: Items[],
    isLoading: boolean,
    totalSell: number,
    listCart: CartItem[],
    denominations: number,
    paymentPeriod: number,
    nextInterestPaymentDate: number,
    currentYield: number,
    fetchDataUserStatus: boolean,
    dataUser: UserItems,
}
export const initialState: global = {
    fillPrice: '',
    listItem: [],
    isLoading: false,
    totalSell: 0,
    listCart: [],
    denominations: 0,
    paymentPeriod: 0,
    nextInterestPaymentDate: 0,
    currentYield: 0,
    fetchDataUserStatus: false,
    dataUser: {
        allowance: '0',
        balance: '0'
    }

}
export default createReducer(initialState, (builder) =>
    builder
        .addCase(valueSearch, (state, action) => {
            state.fillPrice = action.payload.fillPrice
        })
        .addCase(fetchDataOnMarketplace, (state, action) => {
            state.listItem = action.payload.listItem
        })
        .addCase(fetchStatusLoading, (state, action) => {
            state.isLoading = action.payload.isLoading
        })
        .addCase(fetchTotalSellItem, (state, action) => {
            state.totalSell = action.payload.totalSell
        })
        .addCase(fetchCartItems, (state, action) => {
            state.listCart = action.payload.listCart
        })
        .addCase(selectDenominations, (state, action) => {
            state.denominations = action.payload.denominations
        })
        .addCase(selectPaymentPeriod, (state, action) => {
            state.paymentPeriod = action.payload.paymentPeriod
        })
        .addCase(selectNextInterestPaymentDate, (state, action) => {
            state.nextInterestPaymentDate = action.payload.nextInterestPaymentDate
        })
        .addCase(selectCurrentYield, (state, action) => {
            state.currentYield = action.payload.currentYield
        })
        .addCase(fetchDataUserStatus, (state, action) => {
            state.fetchDataUserStatus = action.payload.isDataFetching
        })
        .addCase(fetchDataUserAction, (state, action) => {
            state.dataUser = action.payload.dataUser
        })
)