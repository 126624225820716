import { createReducer } from '@reduxjs/toolkit';
import moment from 'moment';
import { getDateNow } from 'utils/getDateFormat';
import {
    fetchNumberPageCurrent,
    fetchStatusLoading,
    getTotalItem,
    isChangeDate, itemHistory, listItemHistory, searchTransaction, selectEndDay,
    selectStartDay, selectType
} from './actions';
import { Items } from './type';

interface global {
    itemHistory: Items
    listItemHistory: Items[],
    type: string;
    startDay: string;
    endDay: string;
    valueSearch: string;
    isChange: boolean;
    totalItem: number;
    pagenumbermkl: number,
    isLoading: boolean,
}
export const initialState: global = {
    itemHistory: null,
    listItemHistory: [],
    type: "",
    startDay: moment(new Date()).startOf('day').toISOString(),
    endDay: moment(new Date()).endOf('day').toISOString(),
    valueSearch: '',
    isChange: false,
    totalItem: 0,
    pagenumbermkl: 0,
    isLoading: false,
}
export default createReducer(initialState, (builder) =>
    builder
        .addCase(itemHistory, (state, action) => {
            state.itemHistory = action.payload.itemHistory
        })
        .addCase(listItemHistory, (state, action) => {
            state.listItemHistory = action.payload.listItemHistory
        })
        .addCase(selectType, (state, action) => {
            state.type = action.payload.type
        })
        .addCase(selectStartDay, (state, action) => {
            state.startDay = action.payload.startDay
        })
        .addCase(selectEndDay, (state, action) => {
            state.endDay = action.payload.endDay
        })
        .addCase(searchTransaction, (state, action) => {
            state.valueSearch = action.payload.valueSearch
        })
        .addCase(isChangeDate, (state, action) => {
            state.isChange = action.payload.isChange
        })
        .addCase(getTotalItem, (state, action) => {
            state.totalItem = action.payload.totalItem
        })
        .addCase(fetchNumberPageCurrent, (state, action) => {
            state.pagenumbermkl = action.payload.pagenumbermkl
        })
        .addCase(fetchStatusLoading, (state, action) => {
            state.isLoading = action.payload.isLoading
        })
)