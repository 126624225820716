import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.45 22.42C9.78 22.42 10.09 22.2 10.18 21.86C10.29 21.46 10.05 21.05 9.64 20.94C5.58 19.87 2.75 16.19 2.75 11.99C2.75 6.89 6.9 2.74 12 2.74C16.33 2.74 19.17 5.27 20.5 6.8H17.56C17.15 6.8 16.81 7.14 16.81 7.55C16.81 7.96 17.14 8.31 17.56 8.31H21.99C22.04 8.31 22.13 8.3 22.2 8.28C22.29 8.25 22.37 8.21 22.44 8.16C22.53 8.1 22.6 8.02 22.65 7.93C22.7 7.84 22.74 7.73 22.75 7.62C22.75 7.59 22.75 7.57 22.75 7.54V3C22.75 2.59 22.41 2.25 22 2.25C21.59 2.25 21.25 2.59 21.25 3V5.39C19.62 3.64 16.55 1.25 12 1.25C6.07 1.25 1.25 6.07 1.25 12C1.25 16.88 4.54 21.16 9.26 22.4C9.32 22.41 9.39 22.42 9.45 22.42Z" fill="white"/>
      <path d="M12.71 22.73C12.73 22.73 12.75 22.72 12.76 22.72C13.84 22.65 14.9 22.41 15.9 22.02C16.19 21.91 16.39 21.62 16.38 21.31C16.38 21.22 16.36 21.13 16.33 21.05C16.18 20.67 15.73 20.48 15.36 20.62C14.49 20.96 13.58 21.16 12.66 21.23C12.27 21.25 11.96 21.59 11.96 21.99L11.97 22.03C11.99 22.42 12.32 22.73 12.71 22.73ZM18.22 20.58C18.39 20.58 18.55 20.52 18.69 20.42C19.53 19.74 20.27 18.95 20.87 18.07C20.96 17.94 21.01 17.8 21.01 17.65C21.01 17.4 20.89 17.17 20.68 17.03C20.35 16.8 19.87 16.89 19.64 17.21C19.64 17.22 19.64 17.22 19.64 17.22C19.63 17.23 19.62 17.25 19.61 17.26C19.09 18.01 18.46 18.68 17.75 19.24C17.58 19.38 17.47 19.59 17.47 19.82C17.47 19.99 17.52 20.16 17.63 20.3C17.78 20.48 17.99 20.58 18.22 20.58ZM21.56 15.7C21.89 15.7 22.18 15.49 22.27 15.18C22.59 14.15 22.75 13.08 22.75 12V11.99C22.74 11.58 22.41 11.25 22 11.25C21.59 11.25 21.25 11.59 21.25 12C21.25 12.94 21.11 13.86 20.84 14.73C20.82 14.81 20.81 14.88 20.81 14.96C20.81 15.28 21.02 15.57 21.34 15.67C21.41 15.69 21.48 15.7 21.56 15.7Z" fill="white"/>
    </Svg>
  );
};

export default Icon;