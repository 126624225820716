import { createReducer } from '@reduxjs/toolkit'
import { tabActiveFooter } from "./actions"

interface global {
    tabIndex:number
}
export const initialState: global = {
    tabIndex:0
}
export default createReducer(initialState, (builder) =>
  builder
    .addCase(tabActiveFooter, (state, action) => {
      state.tabIndex = action.payload.tabIndex
    })
    
)