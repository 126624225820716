import { createReducer } from '@reduxjs/toolkit'
import { itemOrder, listItemOrder, fetchStatusLoading, fetchNumberPageCurrent, getBalance, getAllowance, actionDispatchMessage } from "./actions"
import { AllowanceItem, ItemWithVestingMap, Items } from './type'

interface global {
    itemOrder: Items
    listItemOrder: ItemWithVestingMap[],
    isLoading: boolean,
    pagenumbermkl: number,
    listAllowanc: AllowanceItem[],
    balance: string,
    messageHash: string,
}
export const initialState: global = {
    itemOrder: null,
    listItemOrder: [],
    isLoading: false,
    pagenumbermkl: 0,
    listAllowanc: [],
    balance: "0",
    messageHash: "",
}
export default createReducer(initialState, (builder) =>
    builder
        .addCase(itemOrder, (state, action) => {
            state.itemOrder = action.payload.itemOrder
        })
        .addCase(listItemOrder, (state, action) => {
            state.listItemOrder = action.payload.listItemOrder
        })
        .addCase(fetchStatusLoading, (state, action) => {
            state.isLoading = action.payload.isLoading
        })
        .addCase(fetchNumberPageCurrent, (state, action) => {
            state.pagenumbermkl = action.payload.pagenumbermkl
        })
        .addCase(getBalance, (state, action) => {
            state.balance = action.payload.balance
        })
        .addCase(getAllowance, (state, action) => {
            state.listAllowanc = action.payload.listAllowanc
        })
        .addCase(actionDispatchMessage, (state, action) => {
            state.messageHash = action.payload.messageHash;
        })
)