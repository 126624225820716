import { ContextApi } from '@pancakeswap/localization'
import { MenuItemsType, MarketplaceIcon, MarketplaceFillIcon, ccqIcon, ccqFillIcon } from '@pancakeswap/uikit'
import { ChainId } from '@pancakeswap/sdk'
import { DropdownMenuItems } from '@pancakeswap/uikit/src/components/DropdownMenu/types'
import { URL_MARKETPLACE } from 'config'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
    items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
    if (!chainId || !item.supportChainIds) {
        return item
    }
    if (item.supportChainIds?.includes(chainId)) {
        return item
    }
    return {
        ...item,
        disabled: true,
    }
}

const config: (
    t: ContextApi['t'],
    isDark: boolean,
    languageCode?: string,
    chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
        [].map((item) => addMenuItemSupported(item, chainId))

export default config
