import { createAction } from '@reduxjs/toolkit'
import { itemAssetType } from 'state/types'
import { ApproveType, DataBalance, DataUser, ListAssetItems, ListIntervestRate, ListUserBalance, ListWrapppedItems, } from './type'

export const itemAsset = createAction<{ itemAsset: itemAssetType }>('asset/itemAsset')
export const fetchAssetItems = createAction<ListWrapppedItems>('asset/fetchAssetItems')
export const fetchStatusLoading = createAction<{ isLoading: boolean }>('asset/fetchStatusLoading')

export const fetchDataUserStatus = createAction<{ isDataFetching: boolean }>('asset/fetchDataUserStatus')
export const fetchDataUserAction = createAction<DataUser>('asset/fetchDataUserAction')
export const fetchUserAllowance = createAction<ApproveType>('asset/fetchUserAllowance')
export const getIntervestRate = createAction<ListIntervestRate>('asset/getIntervestRate')
export const getUserBalance = createAction<ListUserBalance>('asset/getUserBalance')
export const fetchNumberPageCurrent = createAction<{ pagenumbermkl: number }>('asset/fetchNumberPageCurrent')
export const getBalanceAccount = createAction<DataBalance>('asset/getBalanceAccount')
export const actionDispatchAssetMessage = createAction<{ messageHash: string }>('asset/messageActions');
