import { createReducer } from '@reduxjs/toolkit'
import { itemAssetType } from 'state/types';
import {
    itemAsset, fetchAssetItems, fetchStatusLoading, fetchDataUserStatus,
    fetchUserAllowance, getIntervestRate, getUserBalance, fetchNumberPageCurrent, getBalanceAccount, actionDispatchAssetMessage
} from './actions';
import { ListAssestItem, IntervestRate, UserItemsWidthAddress, WrappedItems } from './type';

interface global {
    itemAsset: itemAssetType,
    listWrappedItem: WrappedItems[]
    isLoading: boolean,
    isDataFetching: boolean,
    dataUser: UserItemsWidthAddress[],
    isApprove: boolean,
    listIntervestRate: IntervestRate[],
    listUserBalance: ListAssestItem[],
    pagenumbermkl: number,
    balance: string,
    messageHash: string,
}
export const initialState: global = {
    itemAsset: null,
    listWrappedItem: [],
    isLoading: false,
    isDataFetching: false,
    dataUser: [],
    isApprove: false,
    listIntervestRate: [],
    listUserBalance: [],
    pagenumbermkl: 0,
    balance: "0",
    messageHash: null,
}

export default createReducer(initialState, (builder) =>
    builder
        .addCase(itemAsset, (state, action) => {
            state.itemAsset = action.payload.itemAsset
        })
        .addCase(actionDispatchAssetMessage, (state, action) => {
            state.messageHash = action.payload.messageHash;
        })
        .addCase(fetchAssetItems, (state, action) => {
            state.listWrappedItem = action.payload.listWrappedItem
        })
        .addCase(fetchStatusLoading, (state, action) => {
            state.isLoading = action.payload.isLoading
        })
        .addCase(fetchDataUserStatus, (state, action) => {
            state.isDataFetching = action.payload.isDataFetching
        })
        .addCase(fetchUserAllowance, (state, action) => {
            state.isApprove = action.payload.isApprove
        })
        .addCase(getIntervestRate, (state, action) => {
            state.listIntervestRate = action.payload.listIntervestRate
        })
        .addCase(getUserBalance, (state, action) => {
            state.listUserBalance = action.payload.listUserBalance
        })
        .addCase(fetchNumberPageCurrent, (state, action) => {
            state.pagenumbermkl = action.payload.pagenumbermkl
        })
        .addCase(getBalanceAccount, (state, action) => {
            state.balance = action.payload.balance
        })
)