import { useTranslation } from '@pancakeswap/localization'
import { Button, Menu as UikitMenu, RefreshPageIcon } from '@pancakeswap/uikit'
import { NetworkSwitcher } from 'components/NetworkSwitcher'
import { NextLinkFromReactRouter } from 'components/NextLink'
import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { footerLinks } from './config/footerConfig'
import { useMenuItems } from './hooks/useMenuItems'
import UserMenu from './UserMenu'
import { getActiveMenuItem, getActiveSubMenuItem } from './utils'

const Menu = (props) => {
  const { t } = useTranslation()
  const { pathname } = useRouter()

  const menuItems = useMenuItems()

  const activeMenuItem = getActiveMenuItem({ menuConfig: menuItems, pathname })
  const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, pathname })
  const getFooterLinks = useMemo(() => {
    return footerLinks(t)
  }, [t])

  const onClickReload = useCallback(() => window.location.reload(), [])
  return (
    <>
      <UikitMenu
        linkComponent={(linkProps) => {
          return <NextLinkFromReactRouter to={linkProps.href} {...linkProps} prefetch={false} />
        }}
        rightSide={
          <>
            <NetworkSwitcher />
            <UserMenu />
            <CsButtonRefreshPage onClick={onClickReload}>
              <RefreshPageIcon/>
            </CsButtonRefreshPage>
          </>
        }
        links={menuItems}
        footerLinks={getFooterLinks}
        activeItem={activeMenuItem?.href}
        activeSubItem={activeSubMenuItem?.href}
        {...props}
      />
    </>
  )
}
const CsButtonRefreshPage = styled(Button)`
  width: 48px;
  height: 48px;
  margin-left: 1rem;
  border-radius: 12px;
  border: 1px solid transparent;
  &:active{
    svg{
      transition: 0.5s;
      transform: rotate(360deg);
      -o-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
    }
  }
`
export default Menu
