import { createAction } from '@reduxjs/toolkit'
import { DataBalance, Items, ListAllowance, ListItems } from './type'

export const listItemOrder = createAction<ListItems>('order/listItemOrder')
export const itemOrder = createAction<{ itemOrder: Items }>('order/itemOrder')
export const fetchStatusLoading = createAction<{ isLoading: boolean }>('order/fetchStatusLoading')
export const fetchNumberPageCurrent = createAction<{ pagenumbermkl: number }>('order/fetchNumberPageCurrent')
export const getAllowance = createAction<ListAllowance>('order/getAllowance')
export const getBalance = createAction<DataBalance>('order/getBalance')
export const actionDispatchMessage = createAction<{ messageHash: string }>('order/messageActions');

