import React from "react";
import styled from "styled-components";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  const CsSvg = styled(Svg)`
    width: 140px;
    @media screen and (max-width: 600px) {
      width: 100px;
    }
    @media screen and (max-width: 400px) {
      width: 80px;
    }
  `;

  return (
<CsSvg viewBox="0 0 162 73" fill="none" {...props}>
        <g clipPath="url(#clip0_61862_1064)">
        <g clipPath="url(#clip1_61862_1064)">
        <path d="M4.27815 70.856H1.45364V63.4453H4.27815C6.6827 63.4453 7.82732 64.8606 7.82732 67.1526C7.82732 69.4448 6.69091 70.856 4.27815 70.856ZM4.31109 64.163H2.35535V70.1383H4.31109C6.05272 70.1383 6.9256 69.1186 6.9256 67.1526C6.9256 65.1868 6.05272 64.163 4.31109 64.163Z" fill="#13486B"/>
        <path d="M8.84905 63.9337C8.84796 63.8661 8.8615 63.7991 8.88877 63.7372C8.91603 63.6752 8.95639 63.6198 9.00709 63.5746C9.05782 63.5294 9.11772 63.4954 9.1828 63.4751C9.24787 63.4546 9.31659 63.4484 9.3843 63.4566C9.4516 63.4505 9.51943 63.4582 9.58349 63.4795C9.64755 63.5007 9.70643 63.5351 9.75637 63.5803C9.80629 63.6252 9.84616 63.6802 9.87345 63.7413C9.90073 63.8027 9.91483 63.8689 9.91483 63.9357C9.91483 64.0028 9.90073 64.0689 9.87345 64.1301C9.84616 64.1914 9.80629 64.2462 9.75637 64.2914C9.70643 64.3365 9.64755 64.3707 9.58349 64.3922C9.51943 64.4134 9.4516 64.4212 9.3843 64.415C9.31621 64.4232 9.24716 64.4168 9.18178 64.3963C9.11642 64.3758 9.0563 64.3413 9.00552 64.2957C8.95472 64.2501 8.91448 64.1942 8.88747 64.1317C8.86046 64.0692 8.84736 64.0016 8.84905 63.9337ZM8.95611 70.8673V65.4835H9.82487V70.855L8.95611 70.8673Z" fill="#13486B"/>
        <path d="M10.9477 68.0161C10.9477 66.4541 11.8411 65.3815 13.3028 65.3815C13.7068 65.3455 14.1116 65.4404 14.4562 65.6523C14.8009 65.8642 15.0666 66.1815 15.2132 66.5561V65.4835H16.0367V70.4999C16.0367 72.1559 15.1433 73.0002 13.5375 73.0002C12.0141 73.0002 11.2359 72.2414 11.2359 71.1362H12.0429C12.117 71.952 12.6728 72.4048 13.5869 72.4048C14.5833 72.4048 15.1721 71.8744 15.1721 70.6877V69.5252C14.8674 70.2716 14.2292 70.6469 13.3028 70.6469C11.8411 70.6469 10.9477 69.5742 10.9477 68.0161ZM15.1721 68.0161C15.1721 66.7028 14.4886 66.0054 13.4922 66.0054C12.4958 66.0054 11.8205 66.7028 11.8205 68.0161C11.8205 69.3294 12.504 70.0227 13.4922 70.0227C14.4803 70.0227 15.1721 69.3253 15.1721 68.0161Z" fill="#13486B"/>
        <path d="M17.2766 63.9337C17.2755 63.8661 17.2891 63.7991 17.3163 63.7372C17.3436 63.6752 17.384 63.6198 17.4347 63.5746C17.4854 63.5294 17.5453 63.4954 17.6104 63.4751C17.6755 63.4546 17.7442 63.4484 17.8119 63.4566C17.8792 63.4505 17.947 63.4582 18.0111 63.4795C18.0751 63.5007 18.134 63.5351 18.184 63.5803C18.2339 63.6252 18.2737 63.6802 18.301 63.7413C18.3283 63.8027 18.3424 63.8689 18.3424 63.9357C18.3424 64.0028 18.3283 64.0689 18.301 64.1301C18.2737 64.1914 18.2339 64.2462 18.184 64.2914C18.134 64.3365 18.0751 64.3707 18.0111 64.3922C17.947 64.4134 17.8792 64.4212 17.8119 64.415C17.7438 64.4232 17.6747 64.4168 17.6094 64.3963C17.544 64.3758 17.4839 64.3413 17.4331 64.2957C17.3823 64.2501 17.3421 64.1942 17.3151 64.1317C17.288 64.0692 17.2749 64.0016 17.2766 63.9337ZM17.3796 70.8673V65.4835H18.2525V70.855L17.3796 70.8673Z" fill="#13486B"/>
        <path d="M20.0589 69.6069V66.1033H19.1325V65.4753H20.0589V63.8438H20.9318V65.4753H22.2329V66.1033H20.9318V69.619C20.9318 70.1411 21.1089 70.2634 21.6647 70.2634L22.2329 70.2431V70.8467C22.0229 70.8467 21.6771 70.8997 21.4877 70.8997C20.446 70.9079 20.0589 70.5899 20.0589 69.6069Z" fill="#13486B"/>
        <path d="M23.0739 69.337C23.0739 68.5456 23.5803 67.9299 24.9019 67.8156L25.9026 67.7342C26.6353 67.6687 26.8455 67.5138 26.8455 67.1183C26.8455 66.433 26.4583 66.0048 25.5525 66.0048C24.7291 66.0048 24.272 66.3595 24.2102 67.1183H23.3868C23.3868 66.0577 24.0457 65.3809 25.5608 65.3809C27.076 65.3809 27.7349 66.0577 27.7349 67.1183V69.4798C27.6962 69.9445 27.7536 70.4122 27.9036 70.8543H27.1213C26.9225 70.5531 26.8392 70.1913 26.8867 69.8345C26.5941 70.5851 25.9519 70.9603 24.9968 70.9603C23.7698 70.9603 23.0739 70.3362 23.0739 69.337ZM26.8455 68.5212V67.9911C26.5807 68.2064 26.2447 68.3168 25.9026 68.3011L25.1533 68.3663C24.2926 68.4275 23.9468 68.7742 23.9468 69.2921C23.9468 69.9039 24.3257 70.3647 25.2273 70.3647C26.3019 70.3567 26.8661 69.5124 26.8661 68.513L26.8455 68.5212Z" fill="#13486B"/>
        <path d="M29.0526 70.855V63.4443H29.9276V70.855H29.0526Z" fill="#13486B"/>
        <path d="M39.7083 70.855L38.9631 68.983H35.3688L34.6233 70.855H33.6888L36.6203 63.4443H37.7115L40.643 70.855H39.7083ZM37.1598 64.2966L35.5951 68.2856H38.7161L37.1598 64.2966Z" fill="#13486B"/>
        <path d="M41.347 69.2227H42.1704C42.2239 69.8875 42.4956 70.3647 43.5909 70.3647C44.6861 70.3647 44.9825 69.8875 44.9825 69.3655C44.9825 68.8436 44.6449 68.6396 44.089 68.5497L43.0267 68.3948C42.0057 68.2479 41.5116 67.8727 41.5116 67.0122C41.5116 66.1517 42.0304 65.3809 43.6032 65.3809C45.176 65.3809 45.7071 66.1191 45.7071 67.0366H44.8837C44.8302 66.482 44.6655 65.9722 43.6115 65.9722C42.718 65.9722 42.3762 66.3392 42.3762 66.951C42.3762 67.473 42.6726 67.6402 43.1339 67.7016L44.2045 67.8565C45.213 68.0034 45.8513 68.3866 45.8513 69.3247C45.8513 70.2627 45.2626 70.9562 43.6032 70.9562C41.9439 70.9562 41.347 70.2221 41.347 69.2227Z" fill="#13486B"/>
        <path d="M46.7453 69.2227H47.5688C47.6224 69.8875 47.8941 70.3647 48.9893 70.3647C50.0846 70.3647 50.3521 69.8875 50.3521 69.3655C50.3521 68.8436 50.0187 68.6396 49.4586 68.5497L48.4005 68.3948C47.3794 68.2479 46.8853 67.8727 46.8853 67.0122C46.8853 66.1517 47.4 65.3809 48.977 65.3809C50.554 65.3809 51.0767 66.1191 51.0767 67.0366H50.2533C50.1999 66.482 50.0309 65.9722 48.9812 65.9722C48.0876 65.9722 47.7458 66.3392 47.7458 66.951C47.7458 67.473 48.0381 67.6402 48.5035 67.7016L49.5741 67.8565C50.5826 68.0034 51.2209 68.3866 51.2209 69.3247C51.2209 70.2627 50.6322 70.9562 48.9728 70.9562C47.3135 70.9562 46.7453 70.2221 46.7453 69.2227Z" fill="#13486B"/>
        <path d="M52.1471 68.1706C52.1471 66.5146 53.0075 65.3809 54.6792 65.3809C56.3508 65.3809 57.2237 66.5473 57.2237 68.044C57.2341 68.1307 57.2341 68.2183 57.2237 68.3052H53.0198C53.0198 69.7611 53.7321 70.3444 54.7409 70.3444C55.0983 70.3713 55.4537 70.2725 55.7447 70.0649C56.0356 69.8576 56.2429 69.5549 56.3302 69.2106H57.1085C56.8532 70.2792 56.108 70.948 54.7409 70.948C53.0735 70.9603 52.1471 69.8957 52.1471 68.1706ZM53.0198 67.7219H56.3508C56.2355 66.5555 55.6139 65.9845 54.6792 65.9845C53.7444 65.9845 53.1351 66.5555 53.0198 67.7342V67.7219Z" fill="#13486B"/>
        <path d="M58.7128 69.6069V66.1033H57.7863V65.4753H58.7128V63.8438H59.5857V65.4753H60.8868V66.1033H59.5857V69.619C59.5857 70.1411 59.7627 70.2634 60.3186 70.2634L60.8868 70.2431V70.8467C60.6769 70.8467 60.3309 70.8997 60.1416 70.8997C59.1039 70.9079 58.7128 70.5899 58.7128 69.6069Z" fill="#13486B"/>
        <path d="M71.9287 70.855V64.2886L69.2196 70.855H68.4496L65.7403 64.2886V70.855H64.8676V63.4443H66.2756L68.853 69.5622L71.414 63.4443H72.8222V70.855H71.9287Z" fill="#13486B"/>
        <path d="M73.9896 69.337C73.9896 68.5456 74.4962 67.9299 75.8176 67.8156L76.8183 67.7342C77.5552 67.6687 77.7653 67.5138 77.7653 67.1183C77.7653 66.433 77.3536 66.0048 76.4723 66.0048C75.6489 66.0048 75.1877 66.3595 75.1261 67.1183H74.3025C74.3025 66.0577 74.9655 65.3809 76.4765 65.3809C77.9875 65.3809 78.6505 66.0577 78.6505 67.1183V69.4798C78.6142 69.9445 78.6714 70.4117 78.8192 70.8543H78.0534C77.8565 70.5525 77.7748 70.1909 77.8229 69.8345C77.5265 70.5851 76.8882 70.9603 75.9289 70.9603C74.6855 70.9603 73.9896 70.3362 73.9896 69.337ZM77.7653 68.5212V67.9911C77.4985 68.2057 77.1615 68.3159 76.8183 68.3011L76.0731 68.3663C75.2124 68.4275 74.8625 68.7742 74.8625 69.2921C74.8625 69.9039 75.2414 70.3647 76.1472 70.3647C77.2175 70.3567 77.7653 69.5124 77.7653 68.513V68.5212Z" fill="#13486B"/>
        <path d="M83.5323 70.855V67.4414C83.5323 66.5483 83.2071 66.0793 82.3342 66.0793C81.4612 66.0793 80.8396 66.7766 80.8396 67.8575V70.855H79.9789V65.4836H80.8026V66.4748C80.9461 66.1319 81.1944 65.8422 81.5126 65.646C81.8308 65.4501 82.2032 65.3577 82.5772 65.3816C83.8123 65.3816 84.4052 66.0058 84.4052 67.3068V70.855H83.5323Z" fill="#13486B"/>
        <path d="M85.5265 69.337C85.5265 68.5456 86.0329 67.9299 87.3545 67.8156L88.355 67.7342C89.0921 67.6687 89.302 67.5138 89.302 67.1183C89.302 66.433 88.9109 66.0048 88.0093 66.0048C87.1858 66.0048 86.7246 66.3595 86.6628 67.1183H85.8394C85.8394 66.0577 86.5022 65.3809 88.0134 65.3809C89.5244 65.3809 90.1872 66.0577 90.1872 67.1183V69.4798C90.1509 69.9445 90.2083 70.4117 90.3562 70.8543H89.5737C89.3768 70.5525 89.2951 70.1909 89.3432 69.8345C89.0468 70.5851 88.4085 70.9603 87.4492 70.9603C86.2222 70.9603 85.5265 70.3362 85.5265 69.337ZM89.302 68.5212V67.9911C89.0354 68.2057 88.6985 68.3159 88.355 68.3011L87.6098 68.3663C86.7494 68.4275 86.3992 68.7742 86.3992 69.2921C86.3992 69.9039 86.7781 70.3647 87.6839 70.3647C88.7545 70.3567 89.302 69.5124 89.302 68.513V68.5212Z" fill="#13486B"/>
        <path d="M91.2983 68.0164C91.2983 66.4542 92.1877 65.3816 93.6494 65.3816C94.054 65.3453 94.4594 65.4402 94.8047 65.6521C95.15 65.8641 95.4164 66.1814 95.564 66.5562V65.4836H96.3874V70.5003C96.3874 72.156 95.4939 73.0003 93.8883 73.0003C92.3648 73.0003 91.5865 72.2418 91.5865 71.1365H92.3854C92.4594 71.952 93.0153 72.4049 93.9292 72.4049C94.9299 72.4049 95.5145 71.8747 95.5145 70.6878V69.5255C95.2097 70.2717 94.5717 70.6469 93.6453 70.6469C92.1877 70.6469 91.2983 69.5743 91.2983 68.0164ZM95.5187 68.0164C95.5187 66.7031 94.8392 66.0055 93.8387 66.0055C92.8383 66.0055 92.1671 66.7031 92.1671 68.0164C92.1671 69.3297 92.8505 70.023 93.8387 70.023C94.8269 70.023 95.5187 69.3256 95.5187 68.0164Z" fill="#13486B"/>
        <path d="M97.5152 68.1706C97.5152 66.5146 98.3757 65.3809 100.047 65.3809C101.719 65.3809 102.592 66.5473 102.592 68.044C102.602 68.1307 102.602 68.2183 102.592 68.3052H98.3879C98.3879 69.7611 99.1044 70.3444 100.113 70.3444C100.47 70.3704 100.824 70.2709 101.114 70.0636C101.404 69.8562 101.611 69.5542 101.698 69.2106H102.477C102.221 70.2792 101.476 70.948 100.113 70.948C98.4416 70.9603 97.5152 69.8957 97.5152 68.1706ZM98.3879 67.7219H101.719C101.604 66.5555 100.982 65.9845 100.047 65.9845C99.1128 65.9845 98.5073 66.5555 98.3879 67.7342V67.7219Z" fill="#13486B"/>
        <path d="M109.938 70.8546V67.4204C109.938 66.5273 109.662 66.0786 108.937 66.0786C108.213 66.0786 107.644 66.7557 107.644 67.8365V70.8546H106.821V67.4204C106.821 66.5273 106.537 66.0786 105.833 66.0786C105.129 66.0786 104.561 66.7557 104.561 67.8365V70.8546H103.737V65.4832H104.561V66.4744C104.665 66.1532 104.87 65.8738 105.146 65.6769C105.423 65.48 105.756 65.3764 106.096 65.3812C106.447 65.3452 106.799 65.4451 107.077 65.6605C107.355 65.8758 107.537 66.1895 107.587 66.5356C107.692 66.1956 107.906 65.8991 108.196 65.6908C108.487 65.4823 108.838 65.3737 109.197 65.3812C110.28 65.3812 110.794 66.0052 110.794 67.3064V70.8546H109.938Z" fill="#13486B"/>
        <path d="M111.901 68.1706C111.901 66.5146 112.762 65.3809 114.434 65.3809C116.105 65.3809 116.974 66.5473 116.974 68.044C116.978 68.1316 116.971 68.2192 116.953 68.3052H112.77C112.795 69.7611 113.487 70.3444 114.495 70.3444C114.852 70.3713 115.207 70.2721 115.497 70.0647C115.787 69.8571 115.994 69.5546 116.081 69.2106H116.859C116.608 70.2792 115.862 70.948 114.495 70.948C112.824 70.9603 111.901 69.8957 111.901 68.1706ZM112.77 67.7219H116.101C115.986 66.5555 115.368 65.9845 114.434 65.9845C113.499 65.9845 112.89 66.5555 112.77 67.7342V67.7219Z" fill="#13486B"/>
        <path d="M121.659 70.855V67.4414C121.659 66.5483 121.334 66.0793 120.466 66.0793C119.597 66.0793 118.971 66.7766 118.971 67.8575V70.855H118.098V65.4836H118.921V66.4748C119.065 66.1319 119.313 65.8422 119.632 65.646C119.95 65.4501 120.322 65.3577 120.696 65.3816C121.931 65.3816 122.524 66.0058 122.524 67.3068V70.855H121.659Z" fill="#13486B"/>
        <path d="M124.237 69.6069V66.1033H123.311V65.4753H124.237V63.8438H125.11V65.4753H126.411V66.1033H125.11V69.619C125.11 70.1411 125.287 70.2634 125.843 70.2634L126.411 70.2431V70.8467C126.201 70.8467 125.856 70.8997 125.666 70.8997C124.625 70.9079 124.237 70.5899 124.237 69.6069Z" fill="#13486B"/>
        <path d="M130.1 67.151C130.1 64.8914 131.306 63.3418 133.649 63.3418C135.679 63.3418 136.943 64.5652 136.943 66.0255H136.12C135.984 64.912 135.173 64.08 133.67 64.08C131.928 64.08 131.022 65.1935 131.022 67.151C131.022 69.1088 131.928 70.2223 133.67 70.2223C135.152 70.2223 135.984 69.4309 136.12 68.2116H136.943C136.943 69.7206 135.737 70.9605 133.649 70.9605C131.306 70.9605 130.1 69.4065 130.1 67.151Z" fill="#13486B"/>
        <path d="M137.84 68.1706C137.84 66.5146 138.705 65.3809 140.372 65.3809C142.04 65.3809 142.917 66.5473 142.917 68.044C142.92 68.1316 142.913 68.2192 142.896 68.3052H138.713C138.734 69.7611 139.429 70.3444 140.438 70.3444C140.795 70.3704 141.149 70.2709 141.439 70.0636C141.729 69.8562 141.936 69.5542 142.023 69.2106H142.801C142.55 70.2792 141.801 70.948 140.438 70.948C138.766 70.9603 137.84 69.8957 137.84 68.1706ZM138.713 67.7219H142.044C141.928 66.5555 141.307 65.9845 140.372 65.9845C139.438 65.9845 138.828 66.5555 138.713 67.7342V67.7219Z" fill="#13486B"/>
        <path d="M147.603 70.855V67.4414C147.603 66.5483 147.278 66.0793 146.405 66.0793C145.532 66.0793 144.914 66.7766 144.914 67.8575V70.855H144.041V65.4836H144.865V66.4748C145.009 66.1319 145.257 65.8422 145.575 65.646C145.893 65.4501 146.265 65.3577 146.639 65.3816C147.875 65.3816 148.468 66.0058 148.468 67.3068V70.855H147.603Z" fill="#13486B"/>
        <path d="M150.177 69.6069V66.1033H149.255V65.4753H150.177V63.8438H151.05V65.4753H152.355V66.1033H151.05V69.619C151.05 70.1411 151.227 70.2634 151.787 70.2634L152.355 70.2431V70.8467C152.145 70.8467 151.795 70.8997 151.605 70.8997C150.568 70.9079 150.177 70.5899 150.177 69.6069Z" fill="#13486B"/>
        <path d="M152.89 68.1706C152.89 66.5146 153.751 65.3809 155.423 65.3809C157.094 65.3809 157.963 66.5473 157.963 68.044C157.973 68.1307 157.973 68.2183 157.963 68.3052H153.759C153.784 69.7611 154.476 70.3444 155.484 70.3444C155.841 70.3713 156.196 70.2721 156.486 70.0647C156.776 69.8571 156.983 69.5546 157.069 69.2106H157.848C157.596 70.2792 156.851 70.948 155.484 70.948C153.813 70.9603 152.89 69.8957 152.89 68.1706ZM153.759 67.7219H157.09C156.975 66.5555 156.357 65.9845 155.443 65.9845C154.529 65.9845 153.878 66.5555 153.759 67.7342V67.7219Z" fill="#13486B"/>
        <path d="M159.085 70.855V65.4834H159.908V66.7561C160.201 65.818 160.695 65.4346 161.506 65.4346C161.672 65.4375 161.837 65.454 162 65.4834V66.2339H161.473C160.551 66.2339 159.95 66.9639 159.95 68.2731V70.863L159.085 70.855Z" fill="#13486B"/>
        <path d="M80.2624 50.2754V26.7217C80.2624 26.1808 80.0456 25.662 79.6593 25.2797C79.2733 24.8974 78.7498 24.6825 78.2036 24.6825H73.024C72.4781 24.6825 71.9544 24.4676 71.5684 24.085C71.1823 23.7027 70.9655 23.1839 70.9655 22.6431V17.5041C70.9655 16.9633 71.1823 16.4446 71.5684 16.0621C71.9544 15.6797 72.4781 15.4648 73.024 15.4648H96.8059C97.3518 15.4648 97.8755 15.6797 98.2616 16.0621C98.6476 16.4446 98.8647 16.9633 98.8647 17.5041V22.6309C98.8647 23.1718 98.6476 23.6903 98.2616 24.0729C97.8755 24.4553 97.3518 24.6701 96.8059 24.6701H91.6304C91.0845 24.6701 90.5608 24.885 90.1748 25.2674C89.7887 25.6499 89.5716 26.1687 89.5716 26.7094V50.2633C89.5716 50.8039 89.3548 51.3227 88.9688 51.7052C88.5827 52.0876 88.059 52.3025 87.5131 52.3025H82.3293C81.7841 52.3045 81.2601 52.0924 80.8727 51.7123C80.4851 51.3323 80.2657 50.8156 80.2624 50.2754Z" fill="#13486B"/>
        <path d="M101.888 50.2749V45.1521C101.888 44.6112 102.105 44.0925 102.491 43.7101C102.877 43.3276 103.4 43.1129 103.946 43.1129H118.423C118.969 43.1129 119.493 42.898 119.879 42.5155C120.265 42.1331 120.482 41.6144 120.482 41.0735V40.5556C120.482 40.0148 120.265 39.496 119.879 39.1137C119.493 38.7311 118.969 38.5162 118.423 38.5162H106.54C105.929 38.5283 105.322 38.4165 104.755 38.1879C104.189 37.9591 103.675 37.6185 103.247 37.1867C102.815 36.7585 102.474 36.2495 102.243 35.6893C102.013 35.129 101.898 34.5288 101.904 33.9238V20.0648C101.894 19.463 102.007 18.8654 102.238 18.3086C102.469 17.7518 102.812 17.2474 103.247 16.8264C103.673 16.3895 104.185 16.043 104.752 15.8081C105.318 15.5733 105.926 15.4549 106.54 15.4601H127.741C128.287 15.4601 128.81 15.675 129.196 16.0574C129.582 16.4399 129.799 16.9586 129.799 17.4994V22.6303C129.799 23.1711 129.582 23.6899 129.196 24.0722C128.81 24.4546 128.287 24.6695 127.741 24.6695H113.256C112.71 24.6695 112.186 24.8844 111.8 25.2669C111.414 25.6493 111.197 26.168 111.197 26.7089V27.235C111.197 27.7758 111.414 28.2946 111.8 28.6769C112.186 29.0593 112.71 29.2742 113.256 29.2742H125.147C125.757 29.265 126.363 29.3818 126.926 29.617C127.489 29.8522 127.996 30.2008 128.416 30.6406C128.857 31.0592 129.207 31.5627 129.445 32.1205C129.683 32.678 129.804 33.2778 129.799 33.883V47.7053C129.81 48.3142 129.693 48.9185 129.455 49.4801C129.218 50.0418 128.865 50.5484 128.42 50.9682C127.993 51.3961 127.484 51.7349 126.922 51.9639C126.361 52.1932 125.758 52.308 125.151 52.3018H103.951C103.406 52.3029 102.883 52.0903 102.496 51.7103C102.11 51.3304 101.891 50.8142 101.888 50.2749Z" fill="#13486B"/>
        <path d="M132.814 50.2747V45.1521C132.814 44.6112 133.03 44.0925 133.416 43.7101C133.803 43.3276 134.326 43.1129 134.872 43.1129H149.349C149.895 43.1129 150.418 42.898 150.805 42.5155C151.191 42.1331 151.408 41.6144 151.408 41.0735V40.5556C151.408 40.0148 151.191 39.496 150.805 39.1137C150.418 38.7311 149.895 38.5162 149.349 38.5162H137.466C136.855 38.5283 136.247 38.4165 135.681 38.1879C135.114 37.9591 134.601 37.6185 134.172 37.1867C133.741 36.7585 133.4 36.2495 133.169 35.6893C132.939 35.129 132.823 34.5288 132.83 33.9238V20.0648C132.82 19.463 132.933 18.8654 133.164 18.3086C133.395 17.7518 133.738 17.2474 134.172 16.8264C134.599 16.3895 135.111 16.043 135.678 15.8081C136.244 15.5733 136.852 15.4549 137.466 15.4601H158.658C159.204 15.4601 159.728 15.675 160.114 16.0574C160.5 16.4399 160.717 16.9586 160.717 17.4994V22.6303C160.717 23.1711 160.5 23.6899 160.114 24.0722C159.728 24.4546 159.204 24.6695 158.658 24.6695H144.182C143.635 24.6695 143.112 24.8844 142.726 25.2669C142.34 25.6493 142.123 26.168 142.123 26.7089V27.235C142.123 27.7758 142.34 28.2946 142.726 28.6769C143.112 29.0593 143.635 29.2742 144.182 29.2742H156.068C156.68 29.2648 157.287 29.3812 157.85 29.6166C158.414 29.8518 158.922 30.2004 159.342 30.6406C159.785 31.062 160.136 31.5689 160.373 32.1303C160.61 32.6915 160.729 33.2951 160.721 33.9035V47.7256C160.732 48.3345 160.614 48.9388 160.377 49.5004C160.139 50.0621 159.787 50.5687 159.342 50.9885C158.914 51.4171 158.404 51.7561 157.842 51.9852C157.28 52.2144 156.676 52.3289 156.068 52.3223H134.876C134.605 52.3228 134.336 52.2701 134.085 52.1674C133.834 52.0645 133.607 51.9138 133.415 51.7235C133.223 51.5332 133.071 51.3072 132.968 51.0585C132.865 50.8101 132.812 50.5436 132.814 50.2747Z" fill="#13486B"/>
        <path d="M37.425 15.7104H37.2605C37.1903 15.7104 37.1246 15.7104 37.0545 15.7104C34.7613 15.7104 32.562 14.808 30.9406 13.2017C29.319 11.5955 28.4081 9.41695 28.4081 7.14538C28.4081 7.05972 28.4081 6.97408 28.4081 6.88842C28.412 5.51873 28.0026 4.17911 27.2322 3.04168C26.462 1.90426 25.3657 1.02098 24.0848 0.505322C22.8038 -0.0103344 21.3964 -0.134815 20.0434 0.147875C18.6905 0.430565 17.4538 1.10751 16.4921 2.09175C15.5305 3.07597 14.8879 4.32254 14.6469 5.67128C14.406 7.02002 14.5776 8.40933 15.1397 9.66073C15.7019 10.9121 16.629 11.9684 17.8018 12.6939C18.9746 13.4195 20.3396 13.7811 21.7215 13.7323C24.0146 13.7323 26.2139 14.6347 27.8355 16.2409C29.4569 17.8471 30.3678 20.0257 30.3678 22.2973C30.3678 22.387 30.3678 22.4767 30.3678 22.5664C30.3683 23.9166 30.771 25.2368 31.5257 26.3612C32.2803 27.4854 33.3532 28.3641 34.6101 28.8868C35.867 29.4094 37.2514 29.5527 38.5902 29.299C39.9291 29.0451 41.1623 28.4054 42.1355 27.4601C43.1086 26.5145 43.7784 25.3054 44.0605 23.9846C44.3426 22.6636 44.2248 21.2895 43.7217 20.0346C43.2188 18.7798 42.3528 17.7 41.2327 16.9307C40.1123 16.1614 38.7879 15.7369 37.425 15.7104Z" fill="#81D401"/>
        <path d="M13.8409 36.6615V36.4943C13.8409 36.425 13.8409 36.3597 13.8409 36.2904C13.8409 34.0189 14.7518 31.8404 16.3734 30.2341C17.9949 28.6279 20.1941 27.7254 22.4873 27.7254C22.5738 27.7254 22.6561 27.7254 22.7426 27.7254C24.0899 27.7268 25.4083 27.3383 26.5358 26.6079C27.6634 25.8772 28.5511 24.8365 29.0899 23.613C29.6284 22.3898 29.7948 21.0373 29.5685 19.7216C29.3419 18.406 28.7323 17.1845 27.8149 16.2072C26.8973 15.2299 25.7117 14.5393 24.4034 14.2202C23.0951 13.9011 21.7212 13.9674 20.4505 14.411C19.1798 14.8546 18.0675 15.6562 17.2502 16.7173C16.433 17.7784 15.9464 19.0528 15.8502 20.384C15.8502 20.6206 15.8502 20.8572 15.8502 21.0896C15.8502 23.3612 14.9392 25.5398 13.3176 27.146C11.6961 28.7522 9.49688 29.6547 7.2037 29.6547C7.11312 29.6547 7.02254 29.6547 6.92784 29.6547C5.56514 29.6533 4.23239 30.0505 3.09665 30.7965C1.96092 31.5424 1.07273 32.6039 0.543436 33.8476C0.0141437 35.0916 -0.1327 36.4624 0.121314 37.7887C0.375326 39.1148 1.01889 40.3373 1.97134 41.3028C2.92378 42.2682 4.14271 42.9334 5.47535 43.2156C6.80796 43.4975 8.19495 43.3835 9.46249 42.888C10.73 42.3923 11.8216 41.537 12.6005 40.4295C13.3794 39.3219 13.8109 38.0111 13.8409 36.6615Z" fill="#58C402"/>
        <path d="M37.1575 43.9586H36.9927C36.9228 43.9586 36.8527 43.9586 36.7828 43.9586C34.4896 43.9586 32.2903 43.0564 30.6687 41.4501C29.0473 39.8439 28.1364 37.6653 28.1364 35.3937C28.1364 35.3081 28.1364 35.2223 28.1364 35.1368C28.1403 33.7673 27.7312 32.428 26.9612 31.2908C26.1912 30.1534 25.0957 29.2701 23.8152 28.7543C22.5346 28.2383 21.1276 28.1133 19.7749 28.3952C18.4221 28.6772 17.1853 29.3534 16.2234 30.3368C15.2613 31.3202 14.6181 32.566 14.3761 33.9143C14.1342 35.2625 14.3046 36.6515 14.8654 37.903C15.4264 39.1547 16.3521 40.2117 17.5239 40.9382C18.6957 41.6648 20.0599 42.0278 21.4415 41.9805C23.7346 41.9805 25.9339 42.883 27.5555 44.4892C29.1769 46.0955 30.088 48.274 30.088 50.5455C30.088 50.6354 30.088 50.725 30.088 50.8147C30.0857 52.1649 30.4861 53.4858 31.2389 54.6114C31.9915 55.7372 33.063 56.6175 34.319 57.1424C35.5747 57.6671 36.9591 57.8131 38.2982 57.5615C39.6375 57.3101 40.8719 56.6725 41.8467 55.7287C42.8215 54.785 43.4933 53.5773 43.778 52.2569C44.0626 50.9363 43.9471 49.562 43.4463 48.3062C42.9455 47.0506 42.0816 45.9693 40.9626 45.198C39.8439 44.4267 38.5201 43.9999 37.1575 43.9709V43.9586Z" fill="#F9A138"/>
        <path d="M42.6373 36.8912V36.724C42.6373 36.6546 42.6373 36.5894 42.6373 36.52C42.6373 34.2483 43.5482 32.0698 45.1698 30.4635C46.7912 28.8573 48.9904 27.9551 51.2837 27.9551C51.3702 27.9551 51.4526 27.9551 51.5389 27.9551C52.8863 27.9562 54.2048 27.5679 55.3323 26.8373C56.4598 26.1068 57.3476 25.0659 57.8861 23.8427C58.4249 22.6193 58.5912 21.2668 58.3647 19.9511C58.1383 18.6355 57.5288 17.414 56.6111 16.4367C55.6938 15.4594 54.5079 14.7688 53.1997 14.4497C51.8914 14.1306 50.5176 14.1969 49.2469 14.6405C47.9761 15.0842 46.8638 15.8857 46.0467 16.9468C45.2295 18.0079 44.7428 19.2823 44.6465 20.6135C44.6465 20.8542 44.6465 21.0907 44.6465 21.3232C44.6465 23.5947 43.7356 25.7733 42.114 27.3795C40.4926 28.9857 38.2933 29.8882 36.0001 29.8882C35.9094 29.8882 35.8189 29.8882 35.7243 29.8882C34.3616 29.8868 33.0288 30.2842 31.893 31.03C30.7573 31.7759 29.8691 32.8374 29.3398 34.0814C28.8106 35.3251 28.6636 36.6961 28.9177 38.0222C29.1716 39.3485 29.8152 40.5708 30.7677 41.5363C31.7201 42.5017 32.939 43.1671 34.2718 43.4491C35.6044 43.731 36.9913 43.6172 38.2589 43.1215C39.5264 42.6258 40.618 41.7708 41.3968 40.663C42.1758 39.5554 42.6072 38.2446 42.6373 36.8953V36.8912Z" fill="#F2822F"/>
        </g>
        </g>
        <defs>
        <clipPath id="clip0_61862_1064">
        <rect width="162" height="73" fill="white"/>
        </clipPath>
        <clipPath id="clip1_61862_1064">
        <rect width="162" height="73" fill="white"/>
        </clipPath>
        </defs>
    </CsSvg>
  );
};

export default Icon;
