import { createAction } from '@reduxjs/toolkit'
import { Items, ListItems } from './type'

export const itemHistory = createAction<{itemHistory:Items}>('history/itemHistory')
export const listItemHistory = createAction<{listItemHistory: Items[]}>('history/listItemHistory')
export const fetchStatusLoading = createAction<{isLoading:boolean}>('history/fetchStatusLoading')

// sort
export const selectType = createAction<{type:string}>('history/selectType')
export const selectStartDay = createAction<{startDay:string}>('history/selectStartDay')
export const selectEndDay = createAction<{endDay:string}>('history/selectEndDay')
export const isChangeDate = createAction<{isChange:boolean}>('history/isChangeDate')
export const searchTransaction = createAction<{valueSearch:string}>('history/searchTransaction')
export const getTotalItem = createAction<{totalItem:number}>('history/getTotalItem')
export const fetchNumberPageCurrent= createAction<{ pagenumbermkl: number }>('history/fetchNumberPageCurrent')
